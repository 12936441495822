.md-drppicker {
  margin-top: 16px;

  background: var(--screeb-color-background);
  box-shadow: 0px 2px 24px var(--screeb-color-grey-alpha);
  border-radius: 16px;

  padding: 18px 10px;

  width: max-content !important;

  .ranges {
    padding: 0 14px;

    button {
      background: var(--screeb-color-background) !important;
      border: 1px solid var(--screeb-color-purple-500) !important;
      box-sizing: border-box !important;
      border-radius: 8px !important;

      font-family: Rubik !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 14px !important;
      color: var(--screeb-color-body-text) !important;

      height: 40px;
      display: flex;
      align-items: center;

      padding: 0 16px;

      margin-top: 8px;

      &.active {
        background: var(--screeb-color-purple-500) !important;
        color: var(--screeb-color-white) !important;
      }
    }
  }

  th {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--screeb-color-body-text);
  }

  .dropdowns {
    margin: 0 5px;
    width: 60px !important;
    color: var(--screeb-color-body-text);
  }

  td:hover {
    background: transparent !important;

    span {
      background: var(--screeb-color-purple-alpha) !important;
    }
  }

  td span {
    display: inline-flex;
    border-radius: 100px !important;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
  }

  .active,
  .in-range {
    background: transparent !important;
    text-align: center;
    color: var(--screeb-color-white) !important;
  }

  .in-range span {
    background: var(--screeb-color-purple-alpha) !important;
    color: var(--screeb-color-grey-700) !important;
  }

  .off span {
    opacity: 0.5;
  }

  .end-date span,
  .start-date span {
    background: var(--screeb-color-purple-500) !important;
  }

  .end-date span {
    color: var(--screeb-color-white) !important;
  }
}
