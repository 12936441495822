nz-layout#layout-zone-lvl1 {
  position: relative;
  height: 100%;

  nz-sider#sidebar-lvl1 {
    overflow: auto;
    position: absolute;
    left: 0;
    z-index: 702;
    height: 100%;
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;

    background-color: var(--screeb-color-purple-900);
    box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);

    @media (max-width: 767px) {
      z-index: 802;
    }
  }

  .mobile-trigger,
  .mobile-second-trigger {
    position: fixed;
    visibility: hidden;
    top: 1.75rem;
    left: 0;
    z-index: 801;
    padding: 0.75rem 0.35rem;
    padding-left: 0.2rem;
    background: var(--screeb-color-purple-900);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: auto;
    border: none;

    img {
      height: 14px;
      transform: rotate(180deg);
      transition: transform 0.2s ease-in-out;
    }

    @media (max-width: 767px) {
      visibility: visible;
    }
  }

  .mobile-second-trigger {
    top: 5rem;
    background: var(--screeb-color-background);
    border: 1px solid var(--screeb-color-border-secondary);

    &.is-open {
      img {
        transform: rotate(0deg);
      }
    }
  }

  nz-layout#layout-zone-lvl2 {
    nz-header#header-lvl1 {
      z-index: 701;
      width: calc(100% - 100px);
      margin-left: 100px;
      padding: 0;
      box-shadow: 0px 1px 0px var(--screeb-color-grey-300);

      @media (max-width: 767px) {
        width: 100%;
        margin-left: 0;
      }
    }

    nz-sider#sidebar-lvl2 {
      overflow-x: hidden;
      overflow-y: auto;
      background: var(--screeb-color-background) !important;
      border-right: solid 1px var(--screeb-color-border-secondary) !important;
    }

    nz-layout#layout-zone-lvl3 {
      width: calc(100% - 100px);
      margin-left: 100px;

      @media (max-width: 767px) {
        width: 100%;
        margin-left: 0;
      }

      nz-content {
        overflow-x: hidden;
        display: flex;
        flex: auto;
        flex-direction: column;
        position: relative;
        padding: 32px;
        background: var(--screeb-color-body-background);

        header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          min-height: 104px;
          z-index: 500;
          position: relative;
          width: 100%;
        }

        h1 {
          font-family: Rubik;
          font-style: normal;
          font-weight: normal;
          font-size: 24px;
          line-height: 32px;
          color: var(--screeb-color-body-text);
          margin-bottom: 0px;
        }

        h2 {
          font-family: Rubik;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 28px;
          color: var(--screeb-color-body-text);
          margin-bottom: 8px;
        }

        .loader {
          display: flex;
          justify-content: center;
          width: 100%;
          padding: 100px 0px;
        }

        #page-loader {
          position: fixed;
          top: 0;
          left: 0;
          z-index: 701;
          width: 100%;
          height: 100%;
          background: var(--screeb-color-background);

          img {
            position: relative;
            top: calc(50% - 38px);
            left: calc(50% - 75px);
            width: 150px;
            height: 76px;
          }
        }
      }

      nz-content.no-margin {
        display: flex;
        height: 100%;
        margin: 0px;
        padding: 0px;
      }
    }
  }

  &.is-sider-forced-open,
  &.has-no-sider {
    nz-sider#sidebar-lvl1 {
      position: relative;
    }

    nz-header#header-lvl1 {
      width: 100% !important;
      margin-left: 0 !important;
    }

    nz-layout#layout-zone-lvl3 {
      width: 100% !important;
      margin-left: 0 !important;
    }
  }

  &.is-small-screen {
    nz-sider#sidebar-lvl2 {
      position: absolute;
      overflow: auto;
      height: 100%;
      min-height: 100vh;
      overflow-x: hidden;
      overflow-y: auto;
      z-index: 702;
    }
  }
}

.is-locked {
  filter: grayscale(100%) blur(5px);
  pointer-events: none;
}
