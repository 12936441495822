* {
  box-sizing: border-box;
}

:host {
  display: flex;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

nz-input-group.invalid,
input.invalid,
textarea.invalid,
select.invalid,
input.invalid:focus,
textarea.invalid:focus,
select.invalid:focus,
nz-select.invalid,
nz-select.invalid nz-select-top-control {
  // border: red 1px solid;
  border-color: red !important;
  outline-color: red;
}

/* THEME */
nz-layout {
  button[disabled],
  .ant-btn[disabled] {
    cursor: default;
  }

  .center {
    text-align: center;
  }

  .left {
    text-align: left;
  }

  .right {
    text-align: right;
  }

  // xs and up
  .container {
    margin: 0 auto;
    max-width: 1280px;
    width: 90%;
  }

  // sm and up
  @media only screen and (min-width: 576px) {
    .container {
      width: 85%;
    }
  }

  // lg and up
  @media only screen and (min-width: 992px) {
    .container {
      width: 75%;
    }
  }
}

// .ant-select-item-group {
//   color: var(--screeb-color-body-text);
//   font-weight: bold;
// }

// remove margin between label and sorting buttons
.ant-table .ant-table-column-sorters {
  display: inline-flex;
  align-items: normal;

  .ant-table-column-sorter-full {
    margin-top: -0.2em;
    margin-bottom: 0;
    margin-left: 8px;
  }
}
